import request from '@/utils/request'


//产品资料文档下载
export function productfileUpload(data) {
  return request({
    url: '/archiveManagement/download',
    method: 'GET',
    params: data,
    responseType: 'blob'
  })
}


//获取合同文件类型
export function getUploadType(data) {
  return request({
    url: 'contractManagement/get/file/type',
    method: 'GET',
    params: data,
  })
}
//合同文件下载
export function contractDownload(params) {
  console.log('=========================================================>', params);
  return request({
    url: '/file/download',
    method: 'GET',
    params,
    responseType: 'blob'
  })
}


//文档资料下载
export function documentUpload(data) {
  return request({
    url: 'documentation/download',
    method: 'GET',
    params: data,
    responseType: 'blob'
  })
}



export function userIPlist(data) {
  return request({
    url: 'user/log/list',
    method: 'GET',
    params: data
  })
}


export function fileList(data) {
  return request({
    url: '/file/list',
    method: 'GET',
    params: data
  })
}

export function moudelList(data) {
  return request({
    url: 'documentation/list',
    method: 'GET',
    params: data

  })
}




export function fileRemove(id) {
  return request({
    url: '/file/remove',
    method: 'POST',
    params: {
      id
    }
  })
}


export function planDownload(data) {
  return request({
    url: 'file/download',
    method: 'GET',
    params: data,
    responseType: 'blob'
  })
}


