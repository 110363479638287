<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>协同管理</el-breadcrumb-item>
                <el-breadcrumb-item>供应链协同</el-breadcrumb-item>
                <el-breadcrumb-item>收发货管理</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="button-gutter">
                <el-button type="primary" v-show="userType == '供应商'" @click="addOrederList">新增发货</el-button>
            </div>

            <el-table :data="tableData" border max-height="600">
                <el-table-column align="center" width="50" label="序号">
                    <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderForm.name" align="center" label="订单名称"> </el-table-column>

                <el-table-column prop="orderForm.timeCreated" align="center" label="订单创建时间"> </el-table-column>
                <el-table-column prop="userInfo.name" align="center" label="订单创建人"> </el-table-column>
                <el-table-column prop="buyingTask.materialName" align="center" label="所属任务"> </el-table-column>
                <el-table-column prop="buyingTask.num" width="80" align="center" label="任务总量"> </el-table-column>
                <el-table-column prop="orderForm.makeNum" align="center" label="发货数量"> </el-table-column>
                <el-table-column prop="orderForm.num" align="center" label="剩余数量"> </el-table-column>
                <el-table-column prop="state" align="center" label="订单状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.orderForm.state == 1">已发货</span>
                        <span v-else-if="scope.row.orderForm.state == 2">已收货</span>
                        <span v-else-if="scope.row.orderForm.state == 0">待发货</span>
                    </template>
                </el-table-column>

                <el-table-column align="center" width="300" label="操作">
                    <template slot-scope="scope">
                        <span>
                            <el-popconfirm
                                v-show="userType == '海装风电' || userType == '超级管理员'"
                                confirm-button-text="确定"
                                cancel-button-text="取消"
                                confirm-button-type="success"
                                icon="el-icon-info"
                                icon-color="red"
                                title="确定收货吗？"
                                size="mini"
                                @confirm="Addevaluate(scope.row, scope.$index)"
                            >
                                <el-button
                                    size="mini"
                                    type="success"
                                    slot="reference"
                                    style="margin-right: 10px"
                                    v-show="scope.row.orderForm.state == 1 && scope.row.makeNum == scope.row.num"
                                    >收货</el-button
                                >
                            </el-popconfirm>
                            <el-button
                                size="mini"
                                type="success"
                                slot="reference"
                                disabled
                                style="margin-left: 8px; margin-right: 8px"
                                v-show="scope.row.orderForm.state == 2"
                                >已收货</el-button
                            >

                            <el-popconfirm
                                v-show="userType == '供应商'"
                                confirm-button-text="确定"
                                cancel-button-text="取消"
                                confirm-button-type="success"
                                icon="el-icon-info"
                                icon-color="red"
                                title="确定发货吗？"
                                size="mini"
                                @confirm="DeliverGoods(scope.row, scope.$index)"
                            >
                                <el-button
                                    style="margin-right: 10px"
                                    size="mini"
                                    type="success"
                                    slot="reference"
                                    v-show="(scope.row.orderForm.state == 0 && userType == '供应商')
                                    "
                                    >发货</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="success"
                                    slot="reference"
                                    disabled
                                    style="margin-right: 10px"
                                    v-show="scope.row.orderForm.state == 1"
                                    >已发货</el-button
                                >
                            </el-popconfirm>
                        </span>
                        <!-- <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            confirm-button-type="danger"
            icon="el-icon-info"
            icon-color="red"
            title="确定删除吗？"
            @confirm="deleteFile(scope.row, scope.$index)"
          >
            <el-button size="mini" type="danger" slot="reference"
              >删除</el-button
            >
          </el-popconfirm> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 新增订单 -->
            <el-dialog title="新增发货" width="500px" :visible.sync="dialogVisible" :before-close="Cancel">
                <el-form ref="addOrederForm" :rules="rule" :model="addOrederForm" label-width="100px" label-position="left">
                    <!-- <el-form-item label="订单名称">
                        <el-input v-model="addOrederForm.name" placeholder="请输入订单名称" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item> -->
                    <el-form-item label="所属任务" prop="taskId" :style="{ width: '100%' }">
                        <el-select
                            :style="{ width: '100%' }"
                            v-model="addOrederForm.taskId"
                            placeholder="请选择"
                            @change="taskWhichBelongs"
                        >
                            <el-option v-for="item in taskOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="数量" style="width: 100%">
                        <el-input-number v-model="addOrederForm.makeNum" :min="0" :max="maxNum"></el-input-number>
                    </el-form-item>
                    <!-- <el-form-item label="创建人">
          <el-input
            v-model="addOrederForm.foundName"
            placeholder="请输入创建人"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item> -->
                    <!-- <el-form-item label="订单数量">
          <el-input-number
            v-model="addOrederForm.num"
            placeholder="订单数量"
            :step="1"
          ></el-input-number>
        </el-form-item> -->
                    <!-- <el-form-item label="订单状态">
          <el-radio-group v-model="addOrederForm.state" size="medium">
            <el-radio
              v-for="(item, index) in statusOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item> -->
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="SendOrderLsit(addOrederForm)">确 定</el-button>
                </div>
            </el-dialog>

            <!-- //编辑订单 -->
            <!-- <el-dialog
      title="更新数量"
      :visible.sync="resetdialogVisible"
      :before-close="Cancel"
    >
      <el-form
        ref="resetOrderFrom"
        :model="resetOrderFrom"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="订单名称">
          <el-input
            v-model="resetOrderFrom.name"
            disabled
            placeholder="请输入订单名称"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属采购任务">
          <el-input
            disabled
            v-model="resetOrderFrom.needId"
            placeholder="请输入所属任务名称"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input
            disabled
            v-model="resetOrderFrom.foundName"
            placeholder="请输入创建人"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单数量">
          <el-input
            disabled
            v-model="resetOrderFrom.num"
            placeholder="订单数量"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="发货数量">
          <el-input
            v-model="resetOrderFrom.makeNum"
            placeholder="请输入发货数量"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="resetData('resetOrderFrom')"
          >确 定</el-button
        >
      </div>
    </el-dialog> -->

            <el-dialog title="发货数量" :visible.sync="evaluateDialogVisible" width="30%" :before-close="CancelEvaluate">
                <el-form :model="evaluateFrom" status-icon :rules="enRules" ref="enRules" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="发货数量">
                        <el-input
                            v-model="evaluateFrom.makeNum"
                            placeholder="请输入发货数量"
                            clearable
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="footer">
                    <el-button @click="CancelEvaluate">取 消</el-button>
                    <el-button type="primary" @click="EnterEvaluate('enRules')">确 定</el-button>
                </span>
            </el-dialog>

            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>

<script>
import { orderList, addOrderList, resetOrder, deleteOrder, taskList } from '@/api/document';
import { buyingTaskList } from '@/api/buyingTask';

import { fileDownload } from '@/api/file';

export default {
    name: 'OrderManagement',
    components: {},

    data() {
        return {
            dialogVisible: false,
            resetdialogVisible: false,
            evaluateDialogVisible: false,
            tableData: [],
            pageSize: 10,
            currentPage: 1,
            total: 0,
            num: 0,
            mode: 'add',
            data: {},
            userType: localStorage.getItem('userType'),
            taskOptions: [],
            statusOptions: [],
            addOrederForm: {
                other: {},
                state: 0
            },
            nowData: {
                state: ''
            },
            orederNum: '',
            resetOrderFrom: {},
            evaluateFrom: {
                id: '',
                state: ''
            },
            statusOptions: [
                {
                    label: '已完成',
                    value: '0'
                },
                {
                    label: '未完成',
                    value: '1'
                }
            ],
            rule: {
                buyingTaskId: [{ required: true, message: '请选择采购任务', trigger: 'blur' }],
                makeNum: [{ required: true, message: '请输入数量', trigger: 'blur' }]
            },
            enRules: {
                makeNum: [{ required: true, message: '请输入发货数量', trigger: 'blur' }]
            },
            maxNum: null
        };
    },
    mounted() {
        this.getFileList();
        this.getTaskList();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            const res = await orderList({
                limit,
                page
            });

            if (res.code === 200) {
                //  console.log(res.data);
                this.tableData = res.data;
                // this.userType = localStorage.getItem("userType");
                // console.log(this.userType+"----------------")
                this.total = res.total;
                this.num = parseInt(this.total / limit + 1);
            }
        },
        //获取采购任务select
        async getTaskList() {
            let res = await taskList({
                page: 1,
                limit: 100000
            });
            let data = [];
            if (res.code == 200) {
                // console.log(res.data);
                res.data.forEach((item) => {
                    //console.log(item)
                    data.push(item.buyingTask);
                });
                let taskItem = [];

                data.forEach((item) => {
                    let taskData = {};
                    taskData.label = item.materialName;
                    taskData.value = item.id;
                    taskItem.push(taskData);
                });
                //  console.log(taskItem)
                this.taskOptions = taskItem;
            }
        },
        //删除订单
        async deleteFile(row, $index) {
            let id = row.id;
            const res = await deleteOrder({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
                this.getFileList();
                this.total = res.total;
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        async downloadFile(id) {
            const res = await fileDownload(id);
            download(res, 'test');
        },
        //新增订单的弹框
        addOrederList() {
            this.dialogVisible = true;
        },

        //发送新增订单数据
        async SendOrderLsit(addOrederForm) {
        
            console.log(this.addOrederForm);

            if (this.addOrederForm['makeNum'] == 0) {
                return this.$message.error('订单数量错误');
            } else {
                let res = await addOrderList(this.addOrederForm);
                if (res.code == 200) {
                    this.$message.success('创建成功');
                    this.dialogVisible = false;
                    this.getFileList();
                } else {
                    this.$message.error('创建失败');
                    this.dialogVisible = false;
                }
            }
        },
        // 发货
        async DeliverGoods(row, $index) {
            this.evaluateFrom.id = row.orderForm.id;
            this.evaluateFrom.state = 1;
          
              
           let res  =     await resetOrder(this.evaluateFrom);
           if(res.code==200){
              this.$message.success('发货成功');
            this.evaluateFrom = {};
            this.evaluateDialogVisible = false;
            await this.getFileList();
           }else {
                this.$message.error('发货失败');
            this.evaluateFrom = {};
           }
        },

        // 收货
        async Addevaluate(row, $index) {
            let GoodsData = {};
            GoodsData.id = row.orderForm.id;
            GoodsData.state = 2;

            let res = await resetOrder(GoodsData);
            if (res.code == 200) {
                this.$message.success('收货成功');
                await this.getFileList();
            } else {
                this.$message.error('收货失败');
                await this.getFileList();
            }
        },

        //取消新增
        Cancel() {
            this.dialogVisible = false;
            this.resetdialogVisible = false;
            this.addOrederForm = {};
            this.resetOrderFrom = {};
        },

        //取消收货
        CancelEvaluate() {
            this.evaluateDialogVisible = false;
        },
        //更新进度弹框
        EnterEvaluate(enRules) {
            this.$refs[enRules].validate(async (valid) => {
                if (valid) {
                    await resetOrder(this.evaluateFrom);
                    this.$message.success('发货成功');
                    this.evaluateFrom = {};
                    this.evaluateDialogVisible = false;
                    await this.getFileList();
                } else if (
                    this.evaluateFrom.makeNum == undefined ||
                    (this.evaluateFrom.makeNum == '' && this.evaluateFrom.makeNum <= this.orederNum)
                ) {
                    return this.$message.error('请输正确的数量');
                } else {
                    this.$message.error('发货失败，请检查网络');
                }
            });
        },
        async taskWhichBelongs(taskId) {
            const response = await buyingTaskList({
                id: taskId
            });
            if (response['data'].length < 1) {
                return;
            }
            const data = response['data'][0];
            this.maxNum = data['buyingTask']['num'] - data['buyingTask']['makeNum'];
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}
::v-deep .el-rate {
    line-height: 1;
}
.rateText {
    margin-left: 3px;
}
.evaItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 300px;

    margin: 0 auto;
}
</style>